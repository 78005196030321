import queryString from 'query-string'
import {Toast} from 'antd-mobile'
import {showAlert} from '../components/Alert'
import {isLH, isPW, isWX} from './ua'
import {isLogin as isLoginH5} from './auth'
import {isNode} from '.'
import {getNextUrl} from './url'
// import onVisibilityChange from './onVisibilityChange'
// import {BOARD_KIND} from '../pages/billboard/constants'

export const registerHandler = (name, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.registerHandler(name, data => {
      // eslint-disable-next-line no-console
      console.log('registerHandler callback', name, data)
      let res = data
      try {
        if (data && typeof data === 'string') {
          res = JSON.parse(data)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      callback(res)
    })
  } else {
    // callback()
  }
}

/* 注册Native打开的modal蒙层被点击
  @param callback: func
 */
export const registerOnModalMaskTap = (callback = data => null) => {
  registerHandler('onModalMaskTap', callback)
}

/**
 * 注册注册播放音频开始回调
 * @param callback: func
 */
export const registerOnPlayAudioStart = (callback = data => null) => {
  registerHandler('onPlayAudioStart', callback)
}

/**
 * 注册注册播放音频结束回调
 * @param callback: func
 */
export const registerOnPlayAudioEnd = (callback = data => null) => {
  registerHandler('onPlayAudioEnd', callback)
}

/**
 * 注册播放音频错误回调
 * @param callback: func
 */
export const registerOnPlayAudioError = (callback = data => null) => {
  registerHandler('onPlayAudioError', callback)
}

/**
 * 注册播放音频结束回调
 * @param callback: func
 */
export const registerOnRecordAudioEnd = (callback = data => null) => {
  registerHandler('onRecordAudioEnd', callback)
}

/**
 * 注册录音错误时的回调
 * @param callback: func
 */
export const registerOnRecordAudioError = (callback = data => null) => {
  registerHandler('onRecordAudioError', callback)
}

/* 注册页面重新展示(android完全实现, ios不完全)
  @param callback: func
 */
export const registerOnWebpageAppear = (callback = data => null) => {
  registerHandler('onWebpageAppear', callback)
}

/* 注册页面下拉刷新 (下拉到底的回调，注意： 要通知收起)
  @param params: object -> {
    color: '#ffffff',
    bgColor: '#ffffff',
  }
  @param callback: func
 */
export const registerPagePullDown = (
  params = {},
  callback = async data => null
) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    // window.NiuleJSBridge.registerHandler('onPagePullDown', callback)
    registerHandler('onPagePullDown', data => {
      // eslint-disable-next-line no-console
      console.log('收到onPagePullDown通知')
      let timer = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('delay to close pulldown')
        sendPagePullUp()
        if (timer) {
          clearTimeout(timer) // 3秒内数据回来，清除定时器
          timer = null
        }
      }, 3000) // 3秒保底及时数据出错保证页能收回
      // console.log('PagePullDown回调')

      const clear = () => {
        // 通知收起下拉部分
        sendPagePullUp()
        if (timer) clearTimeout(timer) // 正常情况清除定时器
      }
      try {
        callback(data)
        // eslint-disable-next-line no-console
        console.log('下拉请求数据返回')
        clear()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('pull down error', e)
        clear()
      }
    })
    window.NiuleJSBridge.callHandler('setPullDown', {
      pullable: true,
      bgColor: '#ffffff',
      color: '#101010',
      closeCurrent: false,
      ...params,
    })
  } else {
    // return true
  }
}

/* 注销页面下拉刷新
  @param pullable: bool
 */
export const unregisterPagePullDown = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.unregisterHandler('onPagePullDown')
    window.NiuleJSBridge.callHandler('setPullDown', {
      pullable: false,
    })
  } else {
    // return true
  }
}

/* 收起下来的页面
  @param pullable: bool
 */
export const sendPagePullUp = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('sendPullDown', {
      success: true,
    })
  } else {
    // eslint-disable-next-line no-console
    console.log('sendPullDown true (Pull Page Up)')
  }
}

export const setPullDown = (params = {}) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('setPullDown', {
      pullable: true,
      bgColor: '#ffffff',
      color: '#101010',
      closeCurrent: false,
      ...params,
    })
  }
}

// getDeviceInfo (not used now, 有点项目实现过)
export const getDeviceInfoPromise = new Promise((resolve, reject) => {
  const queries = queryString.parse(window.location.search)
  // eslint-disable-next-line no-constant-condition
  if (true || queries.debug) {
    resolve({})
  } else {
    window.NiuleJSBridge.callHandler('getDeviceInfo', null, info => {
      resolve({
        publicInfo: info,
        mid: info.h_m,
      })
    })
  }
})
getDeviceInfoPromise.catch(() => {})
export const getDeviceInfo = () => getDeviceInfoPromise

export const getDeviceInfoII = () => {
  // 客户端并没有实现：getDeviceInfo
  // eslint-disable-next-line no-constant-condition
  if (true) {
    try {
      const {deviceinfo} = queryString.parse(window.location.search)
      return JSON.parse(decodeURIComponent(deviceinfo))
    } catch (err) {
      return {}
    }
  }
  return new Promise((resolve, reject) => {
    try {
      window.NiuleJSBridge.callHandler('getDeviceInfo', null, info => {
        resolve(info)
      })
    } catch (err) {
      resolve({})
    }
  })
}

export const getLocation = () =>
  new Promise((resolve, reject) => {
    if (isNode()) {
      resolve('')
      return
    }
    // eslint-disable-next-line no-constant-condition
    if (false && isPW(navigator.userAgent)) {
      /* 有点项目实现过
      try {
        window.NiuleJSBridge.callHandler('getLocation', null, data => {
          const {data: position, code, msg} = data
          // code: 高德返回的错误码
          if (code) {
            reject(msg)
          }
          resolve(position)
        })
      } catch (error) {
        reject(error)
      }
      */
      showToast('获取地理位置失败!')
    } else {
      const getSuccess = async position => {
        const data = {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        }
        resolve(data)
      }

      const getFail = err => {
        reject(err.message)
      }

      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(getSuccess, getFail, {
            timeout: 4000,
            // 缓存时间 1min , 1min 内调用返回上次的地址
            maximumAge: 60000,
          })
        } else {
          showToast('获取地理位置失败!')
        }
      } catch (error) {
        reject(error)
      }
    }
  })

/* 打开聊天
  @params id: number
  @params name: string
  @params closeCurrent: bool
*/
export const openChatDialog = (id, name, closeCurrent = false) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('openChatDialog', {
      id: Number(id),
      name,
      closeCurrent,
    })
  } else {
    // openChatDialog
    // console.log('openChatDialog')
  }
}

export const showToast = text => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('toast', {
      text: String(text),
    })
  } else {
    // showAlert({content: text, onConfirm: () => null, noCancel: true})
    Toast.info(text, 2, null, false)
  }
}

// showLiveDialog (not used now, 有点项目实现过)
export const showLiveDialog = ({
  content,
  onConfirm = () => {},
  comfirmDesc = '确定',
  onCanCelDesc = '取消',
  onCanCel = () => {},
}) => {
  if (isNode()) {
    return
  }
  // eslint-disable-next-line no-constant-condition
  if (false && isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler(
      'showLiveDialog',
      {
        content,
        confirmDesc: comfirmDesc,
        cancelDesc: onCanCelDesc,
      },
      data => {
        const {clicked} = data
        if (clicked === 1) {
          onConfirm()
        } else {
          onCanCel()
        }
      }
    )
  } else {
    showAlert({
      content,
      confirmText: comfirmDesc,
      onConfirm,
    })
  }
}

export const isLogin = () => {
  if (isNode()) {
    return Promise.resolve(false)
  }
  if (isPW(navigator.userAgent)) {
    return new Promise((resolve, reject) => {
      try {
        window.NiuleJSBridge.callHandler('isLogin', null, data => {
          resolve(data.isLogin)
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('isLogin', err)
        resolve(false)
      }
    })
  }

  return Promise.resolve(isLoginH5())
}

export const isFullScreen = () => {
  if (isNode()) {
    return Promise.resolve(false)
  }
  if (isPW(navigator.userAgent)) {
    return new Promise((resolve, reject) => {
      try {
        window.NiuleJSBridge.callHandler('isFullScreen', null, data => {
          resolve(data.isFullScreen)
        })
      } catch (err) {
        resolve(false)
      }
    })
  }

  return Promise.resolve(false)
}

/**
 * 唤起登录
 */
export const login = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    // 部分安卓机客户端没有收到调用
    setTimeout(() => {
      window.NiuleJSBridge.callHandler('login', params, callback)
    }, 200)
  } else {
    window.H.replace(
      `/login?next=${getNextUrl(
        window.location.pathname,
        window.location.search
      )}`
    )
    window.H.go(0)
  }
}

export const logout = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('logout', params, callback)
  }
}

/* 成为大神:
*/
export const viewApplyForSkill = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('viewApplyForSkill')
  } else {
    // eslint-disable-next-line no-console
    console.log('viewApplyForSkill')
  }
}

export const viewAbout = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('viewAbout')
  }
}

export const editorProfile = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('editorProfile')
  }
}

/* 进入派单厅:
*/
export const viewSendOrder = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('viewSendOrder')
  } else {
    // eslint-disable-next-line no-console
    console.log('viewSendOrder')
  }
}

/* 立即下单:
*/
export const viewMoreList = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('viewMoreList')
  } else {
    // eslint-disable-next-line no-console
    console.log('viewMoreList')
  }
}

/* 唤起分享:
  @params kind: number 分享类型: 0-直播间  1-技能  2-用户  3-帖子 4-App
*/
// eslint-disable-next-line consistent-return
export const openShare = (kind = 0, params) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('share', {
      kind,
      ...params,
    })
  }
}

// 打开充值中心: 0-金币  1-钻石
export const openVoucherCenter = (
  // eslint-disable-next-line camelcase
  {kind = 0, message = null, act_kind = 0},
  callback = data => null
  // eslint-disable-next-line consistent-return
) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler(
      'voucherCenter',
      {kind, message, act_kind},
      callback
    )
  }
}

/* 打开窗口
  @params params: {url: string, closeCurrent: bool}
*/
export const openWindow = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('openWindow', params, callback)
  } else {
    const {url, closeCurrent = false} = params
    if (closeCurrent) {
      let {hostname} = window.location
      try {
        // eslint-disable-next-line prefer-destructuring
        hostname = new URL(url).hostname
      } catch (err) {
        // eslint-disable-next-line no-console
      }
      if (hostname === window.location.hostname) {
        let u = url
        const prefix = window.location.origin
        if (url.indexOf(prefix) === 0) {
          u = url.replace(prefix, '')
        }
        window.H.replace(u)
      } else {
        // window.open(url)
        window.location.href = url
      }
    } else {
      // window.open(url)
      window.location.href = url
    }
  }
}

export const closeWindow = () => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('closeWindow')
  } else if (window) {
    if (isLH(navigator.userAgent, window.location.hostname)) {
      if (document && document.referrer === '') {
        window.location.href = `https://app.lihan.ltd`
      } else if (
        document &&
        document.referrer &&
        document.referrer.includes('/index.php/user/login')
      ) {
        window.history.go(-1) // TODO:  暂时未解决
      } else {
        window.history.back(-1)
      }
    } else {
      window.history.back(-1)
      // window.close()
    }
  }
}

/* 打开房间 （openLiveRoom）
  @params liveRoom: {}
  @params mid: Number | null
  @params closeCurrent: bool
*/
export const openLiveRoom = ({liveRoom, mid = null, closeCurrent = false}) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('openLiveRoom', {
      liveRoom,
      mid,
      closeCurrent: false,
    })
  } else {
    // nothing
  }
}

/* 打开用户主页
  @params mid: number
  @params closeCurrent: bool
*/
export const viewProfile = params => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('viewProfile', params)
  } else {
    // nothing
  }
}

export const openUserProfile = viewProfile

/* 打开观看新人引导
  @params videoUrl: string
  @params closeCurrent: bool
*/
export const taskWatchGuideVideo = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('taskWatchGuideVideo', params, callback)
  } else {
    // nothing
  }
}

/* 新手任务打开聊天室
  @params closeCurrent: bool
*/
export const taskOpenRoom = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('taskOpenRoom', params, callback)
  } else {
    // nothing
  }
}

/*
  @params closeCurrent: bool
*/
export const openDisHot = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('openDisHot', params, callback)
  } else {
    // nothing
  }
}

/* 新手任务打开充值
  @params kind: bool
*/
export const taskOpenCharge = openVoucherCenter

/**
 * 请求是否可以使用媒体资源(canUseMediaResource)
 * @param {string} type: record | scan
 * Promise
 * @returns {boolean}: 当前是否可以录音, True -> 可用, False -> 不可用
 */
export const canUseMediaResource = (params = {type: 'record'}) => {
  if (isNode()) {
    return Promise.resolve({valid: true})
  }
  if (isPW(navigator.userAgent)) {
    return new Promise((resolve, reject) => {
      try {
        window.NiuleJSBridge.callHandler('canUseMediaResource', params, res => {
          resolve(res)
        })
      } catch (err) {
        reject(err)
      }
    })
  }
  // eslint-disable-next-line no-console
  console.log('canUseMediaResource')
  return Promise.resolve({valid: true})
}

/**
 * 开始录音(startRecordAudio)
 * @param {number} duration: 录音的时长，单位 ms，最大值 600000（10 分钟）, 默认值：60000，必须：否
 * @param {number} sampleRate: 采样率, 默认值：8000，必须：否
 * @param {number} numberOfChannels: 录音通道数，默认值：2，必须：否
 * @param {number} encodeBitRate: 编码码率，默认值：48000，必须：否
 * @param {string} format: 音频格式，默认值：mp3，必须：否
 */
export const startRecordAudio = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  const p = {
    duration: 60000,
    sampleRate: 8000,
    numberOfChannels: 2,
    encodeBitRate: 48000,
    format: 'wav',
    ...params,
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('startRecordAudio', p, callback)
  } else if (isWX(navigator.userAgent)) {
    window.wx.startRecord({
      success: res => {
        callback(true)
      },
      fail: err => {
        // eslint-disable-next-line no-console
        console.log('startRecord err:', err)
        callback(false)
      },
      cancel: res => {
        callback(null)
      },
    })
  } else {
    // nothing
  }
}

/**
 * 结束录音(stopRecordAudio)
 * @returns null: 无
 */
export const stopRecordAudio = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('stopRecordAudio', params, callback)
  } else if (isWX(navigator.userAgent)) {
    window.wx.stopRecord({
      success: res => {
        callback(res.localId)
      },
      fail: err => {
        // eslint-disable-next-line no-console
        console.log('stopRecord err:', err)
        callback(false)
      },
    })
  } else {
    // nothing
  }
}

/**
 * 开始播放音频(startPlayAudio)
 * @param {string} url: 音频url
 * @param {number} duration: 音频时长（毫秒）
 * @returns null: 无
 */
export const startPlayAudio = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('startPlayAudio', params, callback)
  } else if (isWX(navigator.userAgent)) {
    window.wx.playVoice({
      localId: params.url,
    })
  } else {
    // nothing
  }
}

/**
 * 停止播放音频(stopPlayAudio)
 * @returns null: 无
 */
export const stopPlayAudio = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('stopPlayAudio', params, callback)
  } else if (isWX(navigator.userAgent)) {
    window.wx.stopVoice({
      localId: params.url,
    })
  } else {
    // nothing
  }
}

/**
 * 上传文件到oss(uploadMediaResource)
 * @param {string} url: 上传到oss后的url半链
 * @returns null: 无
 */
export const uploadMediaResource = (params, callback = data => null) => {
  if (isNode()) {
    return Promise.resolve({url: ''})
  }
  if (isPW(navigator.userAgent)) {
    return new Promise((resolve, reject) => {
      window.NiuleJSBridge.callHandler(
        'uploadMediaResource',
        params,
        ({url, code}) => {
          if (code) {
            reject(code)
          }
          resolve(url)
        }
      )
    })
  }
  // eslint-disable-next-line no-console
  console.log('uploadMediaResource')
  return Promise.resolve({url: ''})
}

/**
 * 请求遮罩Nav和Tabbar
 * @param {string} color: bool
 * @param {number} opacity: bool
 * @param {bool} mask: bool
 * @param {func} callback
 */
export const maskNavBarAndTabBar = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('maskNavBarAndTabBar', params, callback)
  } else {
    // nothing
  }
}

/**
 * 监听或取消监听系统返回事件，和registerOnSystemReturn一起使用 (listenSystemReturn)
 * @param {bool} listen: 是否监听, 默认值： true
 * @returns null: 无
 */
export const listenSystemReturn = (
  params = {listen: true},
  callback = data => null
) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('listenSystemReturn', params, callback)
  } else {
    // nothing
  }
}

/**
 * 注册系统返回事件回调
 * @param callback: func
 */
export const registerOnSystemReturn = (callback = data => null) => {
  registerHandler('onSystemReturn', callback)
}

/**
 * 请求关闭聊天室浮层组件 (closeChatroomWidget)
 * @returns null: 无
 */
export const closeChatroomWidget = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('closeChatroomWidget', params, callback)
  } else {
    // nothing
  }
}

/**
 * 请求打开全局音乐播放入口
 * @returns null: 无
 */
export const showGlobalPlayer = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('showGlobalPlayer', params, callback)
  } else {
    // nothing
  }
}
/**
 * 请求关闭全局音乐播放入口
 * @returns null: 无
 */
export const closeGlobalPlayer = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('closeGlobalPlayer', params, callback)
  } else {
    // nothing
  }
}

/**
 * 请求开启或关闭滑动返回手势 (setSwipeBackStatus)
 * @param {bool} enable: true → 开启 false → 关闭
 * @returns null: 无
 */
export const setSwipeBackStatus = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('setSwipeBackStatus', params, callback)
  } else {
    // nothing
  }
}

export const setNavigation = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('setNavigation', params, callback)
  } else {
    // nothing
  }
}

export const initMenuConfig = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('initMenuConfig', params, callback)
  } else {
    // nothing
  }
}

/**
 * 用户从h5中请求系统弹窗并继续其他逻辑后并关闭(triggerModalOk)
 * @param null: 无
 * @returns null: 无
 */
export const triggerModalOk = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('triggerModalOk', params, callback)
  } else {
    // nothing
  }
}

/**
 * 用户从h5中请求关闭由Native唤起的Modal(triggerModalClose)
 * @param null: 无
 * @returns null: 无
 */
export const triggerModalClose = (params, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('triggerModalClose', params, callback)
  } else {
    // nothing
  }
}

export const updateStatusBar = (status, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('updateStatusBar', {status}, callback)
  } else {
    // nothing
  }
}

export const storeSet = (key, value, callback = data => null) => {
  if (isNode()) {
    return
  }
  if (isPW(navigator.userAgent)) {
    window.NiuleJSBridge.callHandler('storeSet', {key, value}, callback)
  } else {
    // nothing
  }
}

export const storeGet = key => {
  if (isNode()) {
    return Promise.resolve(null)
  }
  if (isPW(navigator.userAgent)) {
    return new Promise((resolve, reject) => {
      try {
        window.NiuleJSBridge.callHandler('storeGet', {key}, data => {
          resolve(data && data[key])
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  return Promise.resolve(null)
}
