import {createActionType} from '../actions/createActionTypes'

const SET_USER_AGENT = createActionType('SET_USER_AGENT')
const setUserAgent = userAgent => ({
  meta: {userAgent},
  type: SET_USER_AGENT,
})

const SET_QUERY_STRING = createActionType('SET_QUERY_STRING')
const setQueryString = queryString => ({
  meta: {queryString},
  type: SET_QUERY_STRING,
})

const SET_HOSTNAME = createActionType('SET_HOSTNAME')
const setHostname = hostname => ({
  meta: {hostname},
  type: SET_HOSTNAME,
})

const initialState = {
  userAgent: '',
  queryString: '',
  hostname: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AGENT: {
      const {userAgent} = action.meta
      return {
        ...state,
        userAgent,
      }
    }

    case SET_QUERY_STRING: {
      const {queryString} = action.meta
      return {
        ...state,
        queryString,
      }
    }

    case SET_HOSTNAME: {
      const {hostname} = action.meta
      return {
        ...state,
        hostname,
      }
    }
    default:
      return state
  }
}

export {reducer as default, setUserAgent, setQueryString, setHostname}
