import createActionTypes from '../../actions/createActionTypes'

const GET_MANITO_SKILL = createActionTypes('GET_MANITO_SKILL')
const getManitoSkill = () => dispatch =>
  dispatch({
    types: GET_MANITO_SKILL,
    api: {
      path: '/skill/list_user_share_page',
      data: {},
    },
  })

const LIST_AI_DEMOS = createActionTypes('LIST_AI_DEMOS')
const listAiDemos = () => dispatch =>
  dispatch({
    types: LIST_AI_DEMOS,
    api: {
      path: '/stat/list_ai_demos',
      data: {},
    },
  })

const GET_CREATE_RELATIONSHIP = createActionTypes('GET_CREATE_RELATIONSHIP')
const createRelationship = (mid, newPhone, region = 86) => dispatch =>
  dispatch({
    types: GET_CREATE_RELATIONSHIP,
    api: {
      path: '/invite/create_relationship',
      data: {
        mid: Number(mid),
        new_phone: Number(newPhone),
        region_code: Number(region),
      },
    },
  })

const initialState = {
  list: [],
}

const invite = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANITO_SKILL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }
    case LIST_AI_DEMOS.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }
    default:
      return state
  }
}

export {invite as default, getManitoSkill, createRelationship, listAiDemos}
