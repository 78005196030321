import createActionTypes from '../../actions/createActionTypes'

const GET_PROFILE_DETAIL = createActionTypes('GET_PROFILE_DETAIL')
const getProfileDetail = mid => dispatch =>
  dispatch({
    types: GET_PROFILE_DETAIL,
    api: {
      path: '/user/share_page',
      data: {
        mid: Number(mid),
      },
    },
  })
const initialState = {
  desc: '',
  nick: '',
  avatar: '',
  artyPhotos: [],
  regionCode: '',
  onlineStatus: 0,
  basicIntros: [],
  certificateIntros: [],
  skills: [],
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAIL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }

    default:
      return state
  }
}

export {_ as default, getProfileDetail}
