import createActionTypes from '../actions/createActionTypes'

const GET_WALLET_DETAIL = createActionTypes('GET_WALLET_DETAIL')
const getWalletDetail = () => ({
  api: {
    path: '/user/wallet',
    data: {},
  },
  types: GET_WALLET_DETAIL,
})

const initialState = {
  stoneNum: 0,
  stoneName: '',
  preciousNum: 0,
  preciousName: '',
  incomePreciousNum: 0,
  incomeAppreciateNum: 0,
  totalIncomePreciousNum: 0,
  totalWithdrawPreciousNum: 0,
  totalPacketPreciousNum: 0,
  totalPacketIncomePreciousNum: 0,
  totalActPreciousNum: 0,
  canWithdrawPreciousNum: 0,
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_DETAIL.SUCCESS: {
      const wallet = action.payload.data
      return {
        ...state,
        ...wallet,
      }
    }

    default:
      return state
  }
}

export {_ as default, getWalletDetail}
