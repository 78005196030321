import uuid from 'uuid'

export default function(type = '') {
  const t = `${type.toUpperCase()}_${uuid()}`
  return {
    REQUEST: `${t}_REQUEST`,
    SUCCESS: `${t}_SUCCESS`,
    FAILURE: `${t}_FAILURE`,
  }
}

export const createActionType = (type = '') => `${type.toUpperCase()}_${uuid()}`
