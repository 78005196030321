import React, {Component} from 'react'
import {withRouter, Route} from 'react-router'
import PropTypes from 'prop-types'
import qs from 'query-string'
import ReactRouterPropTypes from 'react-router-prop-types'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {fetchDataBeforeRender} from '../utils/ssr'

class PendingNavDataLoader extends Component {
  static propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
      })
    ).isRequired,
    store: PropTypes.shape({
      dispatch: PropTypes.func,
    }).isRequired,
    children: PropTypes.func.isRequired,
  }

  state = {
    previousLocation: null,
  }

  componentWillReceiveProps(nextProps) {
    const navigated = nextProps.location !== this.props.location
    const {routes, store} = this.props

    if (navigated) {
      this.setState({
        previousLocation: this.props.location,
      })

      fetchDataBeforeRender({
        store,
        routes,
        query: qs.parse(nextProps.location.search),
        url: nextProps.location.pathname,
        onProgress: ({progress}) => {
          NProgress.set(progress)
        },
      }).then(() => {
        this.setState({
          previousLocation: null,
        })
      })
    }
  }

  render() {
    const {children, location} = this.props
    const {previousLocation} = this.state

    return (
      <Route
        location={previousLocation || location}
        // location 发生变化时，强制 children 刷新
        render={() => children()}
      />
    )
  }
}

export default withRouter(PendingNavDataLoader)
