/* eslint-disable camelcase */
import createActionTypes from '../actions/createActionTypes'

const CFG_INDEX = createActionTypes('CFG_INDEX')
const cfgIndex = () => ({
  types: CFG_INDEX,
  api: {
    path: '/cfg/index',
    data: {},
  },
})

const GET_POST_DETAIL = createActionTypes('GET_POST_DETAIL')
const getPostDetail = id => ({
  types: GET_POST_DETAIL,
  api: {
    path: '/post/get',
    data: {
      ...(id && {id: Number(id)}),
    },
  },
})

const TOPIC_LIST_POST_BY_TIME = createActionTypes('TOPIC_LIST_POST_BY_TIME')
const topicListPostByTime = params => ({
  types: TOPIC_LIST_POST_BY_TIME,
  api: {
    path: '/topic/list_post_by_time',
    data: {
      ...params,
    },
  },
})

const TOPIC_LIST_POST_BY_SCORE = createActionTypes('TOPIC_LIST_POST_BY_SCORE')
const topicListPostByScore = params => ({
  types: TOPIC_LIST_POST_BY_SCORE,
  api: {
    path: '/topic/list_post_by_score',
    data: {
      ...params,
    },
  },
})

const SD_BIND_CODE = createActionTypes('SD_BIND_CODE')
const sdBindCode = (kind, code = '', jurl = '') => ({
  types: SD_BIND_CODE,
  api: {
    path: '/sd/bind_code',
    data: {
      kind,
      code,
      jurl,
    },
  },
})

const REC_LIST = createActionTypes('REC_LIST')
const recList = params => ({
  api: {
    path: '/order/list_flow_product',
    data: {
      ...params,
    },
  },
  types: REC_LIST,
})

const ORDER_LIST = createActionTypes('ORDER_LIST')
const orderList = params => ({
  api: {
    path: '/user/list_order_charge',
    data: {
      ...params,
    },
  },
  types: ORDER_LIST,
})

const PRIVATE_MSGS_LIST = createActionTypes('PRIVATE_MSGS_LIST')
const privateMsgsList = params => ({
  api: {
    path: '/chatroom/list_private_msg',
    data: {
      ...params,
    },
  },
  types: PRIVATE_MSGS_LIST,
})

const SEND_PRIVATE_MSG = createActionTypes('SEND_PRIVATE_MSG')
const sendPrivateMsg = params => ({
  api: {
    path: '/chatroom/send_private_msg',
    data: {
      ...params,
    },
  },
  types: SEND_PRIVATE_MSG,
})

const MSGS_LIST = createActionTypes('MSGS_LIST')
const msgsList = params => ({
  api: {
    path: '/chatroom/list_history_msg',
    data: {
      ...params,
    },
  },
  types: MSGS_LIST,
})

const SEND_CHATROOM_MSG = createActionTypes('SEND_CHATROOM_MSG')
const sendChatroomMsg = params => ({
  api: {
    path: '/chatroom/send_room_msg',
    data: {
      ...params,
    },
  },
  types: SEND_CHATROOM_MSG,
})

const CART_LIST = createActionTypes('CART_LIST')
const cartList = params => ({
  api: {
    path: '/user/list_carts',
    data: {
      ...params,
    },
  },
  types: CART_LIST,
})

const GET_PRODUCT_DETAIL = createActionTypes('GET_PRODUCT_DETAIL')
const getProductDetail = id => ({
  types: GET_PRODUCT_DETAIL,
  api: {
    path: '/product/get',
    data: {
      ...(id && {id: Number(id)}),
    },
  },
})

const GET_LIVEROOM_DETAIL = createActionTypes('GET_LIVEROOM_DETAIL')
const getLiveroomDetail = id => ({
  types: GET_LIVEROOM_DETAIL,
  api: {
    path: '/liveroom/get',
    data: {
      ...(id && {id: Number(id)}),
    },
  },
})

const LIVEROOM_JOIN = createActionTypes('LIVEROOM_JOIN')
const liveroomJoin = params => ({
  types: LIVEROOM_JOIN,
  api: {
    path: '/liveroom/join',
    data: {
      ...params,
    },
  },
})

const LIVEROOM_LEAVE = createActionTypes('LIVEROOM_LEAVE')
const liveroomLeave = params => ({
  types: LIVEROOM_LEAVE,
  api: {
    path: '/liveroom/leave',
    data: {
      ...params,
    },
  },
})

const LIST_LIVEROOM_REC = createActionTypes('LIST_LIVEROOM_REC')
const listLiveroomRec = params => ({
  types: LIST_LIVEROOM_REC,
  api: {
    path: '/liveroom/list_rec',
    data: {
      ...params,
    },
  },
})

const UPDATE_CARTS = createActionTypes('UPDATE_CARTS')
const updateCarts = (which, productId, num) => ({
  types: UPDATE_CARTS,
  api: {
    path: '/user/update_carts',
    data: {
      which: Number(which),
      productId: Number(productId),
      num: Number(num),
    },
  },
})

const CALC_FEE = createActionTypes('CALC_FEE')
const calcFee = (isCart, addr, productId, quantity) => ({
  types: CALC_FEE,
  api: {
    path: '/order/calc_fee',
    data: {
      isCart: Boolean(isCart),
      addr,
      productId,
      quantity,
      // productId: Number(productId),
    },
  },
})

const initialState = {}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAIL.SUCCESS: {
      const product = action.payload.data
      return {
        ...state,
        ...product,
      }
    }
    case GET_LIVEROOM_DETAIL.SUCCESS: {
      const liveroom = action.payload.data
      return {
        ...state,
        ...liveroom,
      }
    }
    case CFG_INDEX.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }
    case GET_POST_DETAIL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }
    case GET_POST_DETAIL.FAILURE: {
      return {
        ...state,
        err: action.payload.detail || action.payload.msg,
      }
    }
    default:
      return state
  }
}

export {
  _ as default,
  recList,
  getProductDetail,
  getLiveroomDetail,
  sendChatroomMsg,
  sendPrivateMsg,
  liveroomJoin,
  liveroomLeave,
  listLiveroomRec,
  updateCarts,
  orderList,
  msgsList,
  privateMsgsList,
  cartList,
  calcFee,
  sdBindCode,
  cfgIndex,
  getPostDetail,
  topicListPostByTime,
  topicListPostByScore,
}
