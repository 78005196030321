import Cookies from 'js-cookie'

export const isLogin = () => {
  const mid = Cookies.get('mid')
  const token = Cookies.get('token')
  return !!(mid && token)
}

export const getAuthInfo = () => ({
  mid: Cookies.get('mid'),
  token: Cookies.get('token'),
})

export const setAuthInfo = ({mid, token}) => {
  Cookies.set('mid', mid)
  Cookies.set('token', token)
}

export default isLogin
