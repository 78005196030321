import createActionTypes from '../../actions/createActionTypes'

const GET_POST_DETAIL = createActionTypes('GET_POST_DETAIL')
const getPostDetail = id => dispatch =>
  dispatch({
    types: GET_POST_DETAIL,
    api: {
      path: '/post/share_page',
      data: {
        id: Number(id),
      },
    },
  })

const POST_UP = createActionTypes('POST_UP')
const PostUp = params => ({
  types: POST_UP,
  api: {
    path: '/post/up',
    data: {
      ...params,
    },
  },
})

const initialState = {}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_DETAIL.SUCCESS: {
      // const {post = {}} = action.payload.data
      return {
        ...state,
        // ...post,
        ...action.payload.data,
      }
    }
    case GET_POST_DETAIL.FAILURE: {
      return {
        ...state,
        err: action.payload.detail || action.payload.msg,
      }
    }
    default:
      return state
  }
}

export {_ as default, getPostDetail, PostUp}
