/* eslint-disable camelcase */
import createActionTypes from '../actions/createActionTypes'

const GET_VIPINFO = createActionTypes('GET_VIPINFO')
const getVipInfo = () => dispatch =>
  dispatch({
    api: {
      path: '/user/membership',
      data: {},
    },
    types: GET_VIPINFO,
  })
const initialState = {
  member: {},
  nextVipStage: {},
  nextVipStageLeftNum: 0,
  nextVipStageTotalNum: 0,
  nextNobleStage: {},
  nextNobleStageLeftNum: 0,
  nextNobleStageTotalNum: 0,
}
const vip = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIPINFO.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }

    default:
      return state
  }
}

export {vip as default, getVipInfo}
