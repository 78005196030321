import React from 'react'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import PT from 'prop-types'
import './index.scss'

const DarkMask = props => {
  const {isDark} = props

  if (!isDark) {
    return null
  }

  return (
    <div className="DarkMask">
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html className="darkMode" />
      </Helmet>
    </div>
  )
}

DarkMask.propTypes = {
  isDark: PT.bool.isRequired,
}

export default connect(state => {
  const {deviceinfo: {dark_mode: darkMode} = {}} = state

  return {
    isDark: parseInt(darkMode, 10) === 1,
  }
})(DarkMask)
