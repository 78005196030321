import createActionTypes from '../../actions/createActionTypes'

const GET_REPLY_DETAIL = createActionTypes('GET_REPLY_DETAIL')
const getReplyDetail = id => dispatch =>
  dispatch({
    types: GET_REPLY_DETAIL,
    api: {
      path: '/reply/get',
      data: {
        id: Number(id),
      },
    },
  })

const LIST_BY_TIME = createActionTypes('LIST_BY_TIME')
const listByTime = params => dispatch =>
  dispatch({
    types: LIST_BY_TIME,
    api: {
      path: '/reply/list_by_time',
      data: {
        ...params,
      },
    },
  })

const LIST_SUB_BY_TIME = createActionTypes('LIST_SUB_BY_TIME')
const listSubByTime = params => dispatch =>
  dispatch({
    types: LIST_SUB_BY_TIME,
    api: {
      path: '/reply/list_sub_by_time',
      data: {
        ...params,
      },
    },
  })

const REPLY_DEL = createActionTypes('REPLY_DEL')
const ReplyDel = params => ({
  types: REPLY_DEL,
  api: {
    path: '/reply/delete',
    data: {
      ...params,
    },
  },
})

const REPLY_CREATE = createActionTypes('REPLY_CREATE')
const ReplyCreate = params => ({
  types: REPLY_CREATE,
  api: {
    path: '/reply/create',
    data: {
      ...params,
    },
  },
})

const initialState = {}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPLY_DETAIL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }
    default:
      return state
  }
}

export {
  _ as default,
  listSubByTime,
  ReplyCreate,
  ReplyDel,
  getReplyDetail,
  listByTime,
}
