import createActionTypes from '../actions/createActionTypes'

const GET_STONE_PRODUCTS = createActionTypes('GET_STONE_PRODUCTS')
const getStoneProducts = () => ({
  api: {
    path: '/order/list_stone_product',
    data: {},
  },
  types: GET_STONE_PRODUCTS,
})

const GET_COIN_PRODUCTS = createActionTypes('GET_COIN_PRODUCTS')
const getCoinProducts = () => ({
  api: {
    path: '/order/list_precious_product',
    data: {},
  },
  types: GET_COIN_PRODUCTS,
})

const CREATE_ORDER = createActionTypes('CREATE_ORDER')
const createOrder = ({
  mid,
  productId,
  quantity,
  pay,
  money,
  wxCode,
  openid,
}) => ({
  api: {
    path: '/order/create',
    data: {mid, productId, quantity, pay, money, wxCode, wxOpenid: openid},
  },
  types: CREATE_ORDER,
})

const GET_WX_JS_SDK_CONFIG = createActionTypes('GET_WX_JS_SDK_CONFIG')
const getWxJsSdkConfig = () => ({
  api: {
    path: '/account/wx_sign',
    data: {},
  },
  types: GET_WX_JS_SDK_CONFIG,
})

const GET_ALIPAY_ORDER = createActionTypes('GET_ALIPAY_ORDER')
const getAlipayOrder = params => ({
  api: {
    path: '/order/create',
    data: {
      pay: 9,
      quantity: 1,
      ...params,
    },
  },
  types: GET_ALIPAY_ORDER,
})

const GET_WXH5PAY_ORDER = createActionTypes('GET_WXH5PAY_ORDER')
const getWxh5payOrder = params => ({
  api: {
    path: '/order/create',
    data: {
      pay: 11,
      quantity: 1,
      ...params,
    },
  },
  types: GET_WXH5PAY_ORDER,
})

const CHECK_ORDER = createActionTypes('CHECK_ORDER')
const checkOrder = params => ({
  api: {
    path: '/order/info_pw',
    data: {
      ...params,
    },
  },
  types: CHECK_ORDER,
})

const initialState = {
  stoneProducts: [],
  coinProducts: [],
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_STONE_PRODUCTS.SUCCESS: {
      return {
        ...state,
        stoneProducts: action.payload.data.list,
      }
    }

    case GET_COIN_PRODUCTS.SUCCESS: {
      return {
        ...state,
        coinProducts: action.payload.data.list,
      }
    }

    default:
      return state
  }
}

export {
  _ as default,
  getStoneProducts,
  getCoinProducts,
  createOrder,
  getWxJsSdkConfig,
  getAlipayOrder,
  getWxh5payOrder,
  checkOrder,
}
