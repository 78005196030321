import createActionTypes from '../actions/createActionTypes'

const GET_WITHDRAW_ACCOUNT = createActionTypes('GET_WITHDRAW_ACCOUNT')
const getWithdrawAccountInfo = () => ({
  api: {
    path: '/user/get_withdraw_account',
  },
  types: GET_WITHDRAW_ACCOUNT,
})

const GET_BANK_LIST = createActionTypes('GET_BANK_LIST')
const getBankList = () => ({
  api: {
    path: '/cfg/bank_list',
  },
  types: GET_BANK_LIST,
})

const BIND_WITHDRAW_ACCOUNT = createActionTypes('BIND_WITHDRAW_ACCOUNT')
const bindWithdrawAccount = ({
  payKind,
  cardBankId,
  cardNumber,
  cardName,
  aliAccount,
  aliName,
}) => ({
  api: {
    path: '/order/withdraw_bind_account',
    data: {
      payKind,
      // 银行卡
      ...(payKind === 1 && {
        cardBankId,
        cardNumber,
        cardName,
      }),
      // 支付宝
      ...(payKind === 2 && {
        aliAccount,
        aliName,
      }),
    },
  },
  types: BIND_WITHDRAW_ACCOUNT,
})

const DO_WITHDRAW = createActionTypes('DO_WITHDRAW')
const doWithdraw = ({
  amount,
  incomeKind,
  payKind,
  cardBankId,
  cardNumber,
  aliAccount,
  aliName,
  wxCode,
}) => ({
  api: {
    path: '/order/withdraw',
    data: {
      amount,
      incomeKind,
      payKind,
      ...(payKind === 1 && {
        cardBankId,
        cardNumber,
      }),
      ...(payKind === 2 && {
        aliAccount,
        aliName,
      }),
      ...(payKind === 3 && {
        wxCode,
      }),
    },
  },
  types: DO_WITHDRAW,
})

const initialState = {
  accountInfo: {
    cardBank: {
      id: 0,
      name: '',
    },
    cardNumber: '',
    cardName: '',
    aliAccount: '',
    aliName: '',
  },
  bankList: [],
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_WITHDRAW_ACCOUNT.SUCCESS: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...action.payload.data,
        },
      }
    }

    case GET_BANK_LIST.SUCCESS: {
      return {
        ...state,
        bankList: action.payload.data,
      }
    }

    default:
      return state
  }
}

export {
  _ as default,
  getWithdrawAccountInfo,
  getBankList,
  bindWithdrawAccount,
  doWithdraw,
}
