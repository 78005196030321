import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './Button.scss'

export default class Button extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['normal', 'primary']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
      .isRequired,
  }

  static defaultProps = {
    type: 'normal',
    onClick: () => {},
    className: '',
  }

  componentDidMount() {}

  render() {
    const {children, type, onClick, className} = this.props

    return (
      <button
        className={cx('Button', `Button--${type}`, className)}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }
}
