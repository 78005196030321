import qs from 'query-string'

// eslint-disable-next-line import/prefer-default-export
export const fullUrl = url => `${window.location.origin}${url}`

export const fullUrlMustSsl = url => {
  let prefix = window.location.origin
  if (window.location.protocol === 'http:') {
    prefix = prefix.replace('http', 'https')
  }
  return `${prefix}${url}`
}

export const getNextUrl = (pathname, search) => {
  const {next = ''} = qs.parse(search) || {}
  if (next) {
    return encodeURIComponent(next)
  }

  return `${encodeURIComponent(`${pathname}${search}`)}`
}
