import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Button from './Button'
import './Alert.scss'

export default class Alert extends Component {
  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    noCancel: PropTypes.bool,
    footer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
  }

  static defaultProps = {
    content: '',
    onCancel: () => {},
    onConfirm: () => {},
    confirmText: '确定',
    cancelText: '取消',
    noCancel: false,
    footer: undefined,
  }

  componentDidMount() {}

  render() {
    const {
      content,
      onConfirm,
      confirmText,
      onCancel,
      cancelText,
      noCancel,
      footer,
    } = this.props
    return (
      <div className="Alert">
        <div className="Alert__mask" />
        <div className="Alert__container">
          <div className="Alert__content">{content}</div>
          {footer === undefined ? (
            <div className="Alert__footer">
              {!noCancel && (
                <Button
                  onClick={onCancel}
                  type="primary"
                  className="Alert__btnCancel"
                >
                  {cancelText}
                </Button>
              )}
              <Button
                onClick={onConfirm}
                type="primary"
                className="Alert__btnConfirm"
              >
                {confirmText}
              </Button>
            </div>
          ) : (
            footer
          )}
        </div>
      </div>
    )
  }
}

export const showAlert = ({
  content,
  onConfirm = () => {},
  onCancel = () => {},
  confirmText,
  noCancel = false,
  footer,
}) => {
  const ele = document.createElement('div')
  document.body.appendChild(ele)

  const destory = () => {
    ReactDOM.unmountComponentAtNode(ele)
    document.body.removeChild(ele)
  }

  ReactDOM.render(
    <Alert
      content={content}
      confirmText={confirmText}
      onCancel={() => {
        destory()
        onCancel()
      }}
      onConfirm={async () => {
        const canceled = await onConfirm()
        if (!canceled) {
          destory()
        }
      }}
      noCancel={noCancel}
      footer={footer}
    />,
    ele
  )
}
