/* eslint-disable no-param-reassign, no-underscore-dangle, no-plusplus, no-console, vars-on-top, no-var, no-multi-assign, func-names */
import parseMessageFromNative from './parseMessageFromNative'
import {isPW} from './ua'

const _ = '' // eslint-disable-line no-unused-vars
;(function() {
  if (!isPW(navigator.userAgent)) {
    // 如果在站外页面注入，会导致页面往 app 里跳转
    const nop = () => {}
    window.NiuleJSBridge = {
      init: nop,
      send: nop,
      registerHandler: nop,
      unregisterHandler: nop,
      callHandler: nop,
    }
    return
  }

  let messagingIframe
  let sendMessageQueue = []
  let receiveMessageQueue = []
  const messageHandlers = {}

  const CUSTOM_PROTOCOL_SCHEME = 'peiwan'
  const QUEUE_HAS_MESSAGE = '__QUEUE_MESSAGE__/'

  const responseCallbacks = {}
  let uniqueId = 1

  function _createQueueReadyIframe(doc) {
    messagingIframe = doc.createElement('iframe')
    messagingIframe.style.display = 'none'
    doc.documentElement.appendChild(messagingIframe)
  }

  /* set default messageHandler */
  function init(messageHandler) {
    if (NiuleJSBridge._messageHandler) {
      throw new Error('NiuleJSBridge.init called twice')
    }
    NiuleJSBridge._messageHandler = messageHandler
    const receivedMessages = receiveMessageQueue
    receiveMessageQueue = null
    for (let i = 0; i < receivedMessages.length; i++) {
      _dispatchMessageFromNative(receivedMessages[i])
    }
  }

  function send(data, responseCallback) {
    _doSend(
      {
        data,
      },
      responseCallback
    )
  }

  function registerHandler(handlerName, handler) {
    messageHandlers[handlerName] = handler
  }

  function unregisterHandler(handlerName) {
    delete messageHandlers[handlerName]
  }

  function callHandler(handlerName, data, responseCallback) {
    _doSend(
      {
        handlerName,
        data,
      },
      responseCallback
    )
  }

  /* sendMessage add message, 触发native处理 sendMessage */
  function _doSend(message, responseCallback) {
    if (responseCallback) {
      const callbackId = `cb_${uniqueId++}_${new Date().getTime()}`
      responseCallbacks[callbackId] = responseCallback
      message.callbackId = callbackId
    }
    sendMessageQueue.push(message)
    messagingIframe.src = `${CUSTOM_PROTOCOL_SCHEME}://${QUEUE_HAS_MESSAGE}`
  }

  /* 提供给native调用,该函数作用:获取sendMessageQueue返回给native,由于android不能直接获取返回的内容,所以使用url shouldOverrideUrlLoading 的方式返回内容 */
  function _fetchQueue() {
    const messageQueueString = JSON.stringify(sendMessageQueue)
    sendMessageQueue = []
    /* android can't read directly the return data, so we can reload iframe src to communicate with java */
    messagingIframe.src = `${CUSTOM_PROTOCOL_SCHEME}://return/_fetchQueue/${encodeURIComponent(
      messageQueueString
    )}`
  }

  /* 提供给native使用, */
  function _dispatchMessageFromNative(messageJSON) {
    setTimeout(() => {
      const message = parseMessageFromNative(messageJSON)
      let responseCallback
      /* java call finished, now need to call js callback function */
      if (message.responseId) {
        responseCallback = responseCallbacks[message.responseId]
        if (!responseCallback) {
          return
        }
        responseCallback(message.responseData)
        delete responseCallbacks[message.responseId]
      } else {
        /* 直接发送 */
        if (message.callbackId) {
          const callbackResponseId = message.callbackId
          responseCallback = function(responseData) {
            _doSend({
              responseId: callbackResponseId,
              responseData,
            })
          }
        }

        let handler = NiuleJSBridge._messageHandler
        if (message.handlerName) {
          handler = messageHandlers[message.handlerName]
        }
        /* 查找指定handler */
        try {
          handler(message.data, responseCallback)
        } catch (exception) {
          console.log(
            'NiuleJSBridge: WARNING: javascript handler threw.',
            message,
            exception
          )
        }
      }
    })
  }

  /* 提供给native调用,receiveMessageQueue 在会在页面加载完后赋值为null */
  function _handleMessageFromNative(messageJSON) {
    // console.log(messageJSON)
    if (receiveMessageQueue && receiveMessageQueue.length > 0) {
      receiveMessageQueue.push(messageJSON)
    } else {
      _dispatchMessageFromNative(messageJSON)
    }
  }

  var NiuleJSBridge = (window.NiuleJSBridge = {
    version: '1.0.0',
    init,
    send,
    registerHandler,
    unregisterHandler,
    callHandler,
    _fetchQueue,
    _handleMessageFromNative,
  })

  const doc = document
  _createQueueReadyIframe(doc)
  const readyEvent = doc.createEvent('Events')
  readyEvent.initEvent('NiuleJSBridgeReady')
  readyEvent.bridge = NiuleJSBridge
  doc.dispatchEvent(readyEvent)
})()
