const parseMessageFromNative = JSONStr => {
  if (!JSONStr) {
    return ''
  }

  try {
    return JSON.parse(JSONStr)
  } catch (err) {} // eslint-disable-line no-empty

  // safari 不支持 lookbehind 写法
  // const nicknameReg = /(?<="nickname":")[\s\S]*?(?=",)/
  const nicknameReg = /"nickname":"([\s\S]*?)",/
  let nickname = ''
  const placeholder = '!@@$%##~@@$##!#@~'
  const fixedJSONStr = JSONStr.replace(nicknameReg, (match, p1) => {
    nickname = p1
    return `"nickname":"${placeholder}",`
  })

  const ret = JSON.parse(fixedJSONStr)

  const walkObject = obj => {
    Object.entries(obj).forEach(([key, value]) => {
      if (value instanceof Object) {
        walkObject(value)
      } else if (key === 'nickname' && value === placeholder) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = nickname
      }
    })
  }

  walkObject(ret)

  return ret
}

export default parseMessageFromNative
