import createActionTypes from '../../actions/createActionTypes'

const GET_SKILL = createActionTypes('GET_SKILL')
const getSkill = (mid, skillId) => dispatch =>
  dispatch({
    types: GET_SKILL,
    api: {
      path: '/user/skill_share_page',
      data: {
        mid: Number(mid),
        skillId: Number(skillId),
      },
    },
  })

const SKILL_LIST_USER = createActionTypes('SKILL_LIST_USER')
const skillListUser = params => ({
  types: SKILL_LIST_USER,
  api: {
    path: '/skill/list_user_rec',
    data: {
      ...params,
    },
  },
})

const initialState = {
  member: {},
  skill: {},
  starList: [],
}

const skill = (state = initialState, action) => {
  switch (action.type) {
    case GET_SKILL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }

    default:
      return state
  }
}

export {skill as default, getSkill, skillListUser}
