const SET_DEVICE_INFO = 'SET_DEVICE_INFO'
const setDeviceInfo = deviceinfo => ({
  deviceinfo,
  type: SET_DEVICE_INFO,
})

const initialState = {
  // h_av: '',
  // paddingTop: 0,
  // h_h5plat: 0,
}

const deviceinfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE_INFO: {
      return {
        ...state,
        ...action.deviceinfo,
      }
    }

    default:
      return state
  }
}

export {deviceinfo as default, setDeviceInfo}
