import Loadable from 'react-loadable'

export default [
  {
    path: '/hybrid/lh/sz',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSz" */ '../pages/lh/sz'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/login',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzLogin" */ '../pages/lh/sz/login'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/entry',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzEntry" */ '../pages/lh/sz/entry'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/home',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzHome" */ '../pages/lh/sz/home'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/ting',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzTing" */ '../pages/lh/sz/ting'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/xie',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzXie" */ '../pages/lh/sz/xie'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/me',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzMe" */ '../pages/lh/sz/me'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/detail',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzDetail" */ '../pages/lh/sz/detail'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/audio',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzAudio" */ '../pages/lh/sz/audio'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/sz/hidden',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhSzHidden" */ '../pages/lh/sz/hidden'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/qr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhQr" */ '../pages/lh/qr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/live/rec',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhLiveRec" */ '../pages/lh/live/rec'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/live/detail',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhLiveDetail" */ '../pages/lh/live/detail'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh',
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "page-Lh" */ '../pages/lh'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/detail',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhDetail" */ '../pages/lh/detail'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/gift',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhGift" */ '../pages/lh/gift'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/login',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhLogin" */ '../pages/lh/login'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/lh/404',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-LhNotFound" */ '../pages/lh/404'),
      loading: () => null,
    }),
  },
]
