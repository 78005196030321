import createActionTypes from '../../actions/createActionTypes'

const GET_ROOM_DETAIL = createActionTypes('GET_ROOM_DETAIL')
const getRoomDetail = ({id}) => dispatch =>
  dispatch({
    types: GET_ROOM_DETAIL,
    api: {
      path: '/liveroom/share_page',
      data: {
        id: Number(id),
      },
    },
  })

const initialState = {
  host: {},
  audience: [],
  onlineNum: 0,
  title: '',
  showMemberInfo: '',
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROOM_DETAIL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }

    default:
      return state
  }
}

export {_ as default, getRoomDetail}
