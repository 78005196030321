import thunk from 'redux-thunk'
import {createStore, applyMiddleware, compose} from 'redux'
import reducers from '../reducers'
import api from './middlewares/api'

export default function(...args) {
  const [ctx, ...rest] = args
  const middlewares = [thunk, api(ctx)]
  const finalCreateStore = compose(applyMiddleware(...middlewares))(createStore)
  return finalCreateStore(reducers, ...rest)
}
